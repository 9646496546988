export enum StylesEnum {
  primary,
  secondary,
}

export enum APINames {
  IA = "ai",
  THERAPIST = "therapist",
  PATIENT = "patient",
  COMMENT = "comment",
  TOPIC = "topic",
  AUTH = "auth",
  ADMIN = "admin",
  CATALOGS = "catalogs",
  REGISTRATION = "registration",
  APPOINTMENT = "appointment",
  TOKEN = "token",
  ANALYTICS = "analytics",
  EMAIL = "email",
  FILE = "file",
  NOTIFICATION = "notification",
  ZIP_CODES = "zip-code-cities",
  PROMOTIONS = "promotions",
}
export enum OrderEnum {
  ASC = 1,
  DESC = -1,
}

export enum ToastSeverity {
  Success = 0,
  Info = 1,
  Warning = 2,
  Error = 3,
}

export enum StylesMode {
  light = 0,
  dark = 1,
}

export enum UserType {
  Admin = 0,
  Therapist = 1,
  Patient = 2,
  None = 3,
}

export enum SpanishUserTypes {
  "Administrador" = 0,
  "Terapeuta" = 1,
  "Paciente" = 2,
}

export enum Currency {
  MXN = "MXN",
  USD = "USD",
  EUR = "EUR",
}

export enum CurrencySpanish {
  MXN = "Peso mexicano",
  USD = "Dólar estadounidense",
  EUR = "Euro",
}

export enum FileType {
  PROFILE_PIC = "profile_pic",
}

export enum FileExtension {
  PNG = "png",
  JPG = "jpg",
  JPEG = "jpeg",
}

export enum LoadingEnum {
  idle = 0,
  pending = 1,
  succeeded = 2,
  failed = 3,
}

export enum InputType {
  Text,
  Date,
  Select,
  Autocomplete,
  Projected,
  Switch,
  Upload,
  Textarea,
}

export enum Theories {
  Conductual,
  Interconductual,
  CognitivoConductual,
  Humanismo,
  Psicoanalisis,
  Gestalt,
  Escepticismo,
  HistoricoCultural,
  Complejidad,
}

export enum Subscription {
  Free,
  Trial,
  Premium,
  Pro,
}

export enum SchoolLevel {
  Lic = "Licenciatura",
  Master = "Maestria",
  Phd = "Doctorado",
  PosPhd = "Posdoctorado",
}

export enum Modality {
  Presencial,
  Online,
}

export enum ModalitySpanish {
  Presencial,
  "En línea",
}

export enum AssignedTherapistStatus {
  None,
  Pending,
  Accepted,
  TherapistRefused,
  TherapistCancelled,
  PatientDropOut,
}

export enum ProposalStatus {
  Send,
  Accepted,
  Refused,
}

export enum WeekDaysEnum {
  sunday,
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
}

export enum CommentFor {
  Therapist,
  Patient,
}

export enum ProvidedServicesTypes {
  Individual,
  Couple,
  Family,
  Groupal,
}

export enum Gender {
  F, // femenino
  M, // masculino
  O, // otro
}

export enum GenderSpanish{
  Femenino,
  Masculino,
  Otro
}

export enum TokenType {
  ResetPassword,
  ValidateEmail,
}

export enum SocialMediaReferrer {
  FB = "facebook",
  TIKTOK = "tiktok",
  INSTA = "instagram",
  GOOGLE = "google",
}